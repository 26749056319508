var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(/*view selected trade modal*/)+" "),_c('b-modal',{staticClass:"tradeModal",attrs:{"id":"tradeModal","scrollable":"","title":_vm.$t('equity_bond.titles.view_selected_trade'),"size":"xl","ok-title":_vm.$t('equity_bond.modals.view_trade_modal_ok'),"cancel-title":_vm.$t('equity_bond.modals.modal_cancel'),"body-bg-variant":'light',"header-bg-variant":'dark'},on:{"hidden":_vm.resetTradeModal,"ok":_vm.tradeOkOperation},model:{value:(_vm.viewSelectedTradeModal),callback:function ($$v) {_vm.viewSelectedTradeModal=$$v},expression:"viewSelectedTradeModal"}},[_c('b-overlay',{attrs:{"show":_vm.tradeModalLoading,"rounded":"sm","no-fade":""}},[_c('b-container',[_c('validation-observer',{ref:"tradeValidation"},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-card',{staticStyle:{"width":"100% !important"},attrs:{"title":_vm.$t('equity_bond.titles.order_details')}},[_c('hr'),_c('b-row',[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"disabled":true,"placeholder":_vm.$t('equity_bond.trade_form.order_date'),"onClose":"testClose","config":_vm.orderDateConfig,"state":errors.length > 0 ? false : null},model:{value:(_vm.tradeForm.orderDate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderDate", $$v)},expression:"tradeForm.orderDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"disabled":true,"id":"orderTime","raw":false,"options":_vm.cleaveOptions.time,"placeholder":_vm.$t('equity_bond.trade_form.order_time'),"state":errors.length > 0 ? false : null},model:{value:(_vm.tradeForm.orderTime),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderTime", $$v)},expression:"tradeForm.orderTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.orderDurationInputsVisible)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_duration_date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_duration_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.$t('equity_bond.trade_form.order_duration_date'),"onClose":"testClose","config":_vm.dateConfig,"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.orderDurationDate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderDurationDate", $$v)},expression:"tradeForm.orderDurationDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4194393714)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.orderDurationInputsVisible)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_duration_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_duration_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"orderDurationTime","raw":false,"options":_vm.cleaveOptions.time,"placeholder":_vm.$t('equity_bond.trade_form.order_duration_time'),"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.orderDurationTime),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderDurationTime", $$v)},expression:"tradeForm.orderDurationTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3165015996)})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.entry_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.entry_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optEntryTypes,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_entry_type'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.entryType),callback:function ($$v) {_vm.$set(_vm.tradeForm, "entryType", $$v)},expression:"tradeForm.entryType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.buy_sell')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.buy_sell'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optbuySells,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_buy_sell'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.buySell),callback:function ($$v) {_vm.$set(_vm.tradeForm, "buySell", $$v)},expression:"tradeForm.buySell"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optOrderTypes,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_order_type'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.orderType),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderType", $$v)},expression:"tradeForm.orderType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.time_in_force')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.time_in_force'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optTimeInForces,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_time_in_force'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.timeInForce),callback:function ($$v) {_vm.$set(_vm.tradeForm, "timeInForce", $$v)},expression:"tradeForm.timeInForce"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.instrumentType == 'FI')?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.cpty_order_date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.cpty_order_date'),"rules":_vm.tradeForm.instrumentType == 'FI' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"border":"1px solid #CD853F"},attrs:{"placeholder":_vm.$t('equity_bond.trade_form.cpty_order_date'),"onClose":"testClose","config":_vm.dateConfig,"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.cptyOrderDate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "cptyOrderDate", $$v)},expression:"tradeForm.cptyOrderDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4055975555)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.instrumentType == 'FI')?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.cpty_order_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.cpty_order_time'),"rules":_vm.tradeForm.instrumentType == 'FI' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",staticStyle:{"border":"1px solid #CD853F"},attrs:{"id":"orderTime","raw":false,"options":_vm.cleaveOptions.time,"placeholder":_vm.$t('equity_bond.trade_form.cpty_order_time'),"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.cptyOrderTime),callback:function ($$v) {_vm.$set(_vm.tradeForm, "cptyOrderTime", $$v)},expression:"tradeForm.cptyOrderTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3850273971)})],1):_vm._e()],1),(
                      _vm.tradeForm.orderType == 'Stop' ||
                        _vm.tradeForm.orderType == 'Trailing Stop'
                    )?_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.stop_price')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.stop_price'),"rules":_vm.tradeForm.orderType == 'Stop'
                            ? 'required|min_value:0'
                            : '' || _vm.tradeForm.orderType == 'Trailing Stop'
                            ? 'required|min_value:0'
                            : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"stopPrice","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.stop_price'),"disabled":true},model:{value:(_vm.tradeForm.stopPrice),callback:function ($$v) {_vm.$set(_vm.tradeForm, "stopPrice", _vm._n($$v))},expression:"tradeForm.stopPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3868803070)})],1)],1):_vm._e(),(_vm.tradeForm.orderType == 'Trailing Stop')?_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.trailing_amount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.trailing_amount'),"rules":_vm.tradeForm.orderType == 'Trailing Stop'
                            ? 'required|min_value:0'
                            : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"id":"trailingAmount","name":"trailingAmount","type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.trailing_amount'),"autofocus":""},model:{value:(_vm.tradeForm.trailingAmount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "trailingAmount", _vm._n($$v))},expression:"tradeForm.trailingAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1808710787)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('hr')]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"enable OCO orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":true},model:{value:(_vm.tradeForm.enableConnectedOCOOrders),callback:function ($$v) {_vm.$set(_vm.tradeForm, "enableConnectedOCOOrders", $$v)},expression:"tradeForm.enableConnectedOCOOrders"}},[_vm._v(" "+_vm._s(_vm.$t('equity_bond.trade_form.enable_oco'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.enableConnectedOCOOrders)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.take_profit_limit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.take_profit_limit'),"rules":_vm.tradeForm.enableConnectedOCOOrders
                              ? 'required|min_value:0'
                              : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"takeProfitLimit","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.take_profit_limit'),"disabled":true},model:{value:(_vm.tradeForm.takeProfitLimit),callback:function ($$v) {_vm.$set(_vm.tradeForm, "takeProfitLimit", _vm._n($$v))},expression:"tradeForm.takeProfitLimit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,900483948)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.enableConnectedOCOOrders)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.stop_loss_stop')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.stop_loss_stop'),"rules":_vm.tradeForm.enableConnectedOCOOrders
                              ? 'required|min_value:0'
                              : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"stopLossStop","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.stop_loss_stop'),"disabled":true},model:{value:(_vm.tradeForm.stopLossStop),callback:function ($$v) {_vm.$set(_vm.tradeForm, "stopLossStop", _vm._n($$v))},expression:"tradeForm.stopLossStop"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,317104535)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.enableConnectedOCOOrders)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.stop_loss_limit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.stop_loss_limit'),"rules":_vm.tradeForm.enableConnectedOCOOrders
                              ? 'required|min_value:0'
                              : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"stopLossLimit","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.stop_loss_limit'),"disabled":true},model:{value:(_vm.tradeForm.stopLossLimit),callback:function ($$v) {_vm.$set(_vm.tradeForm, "stopLossLimit", _vm._n($$v))},expression:"tradeForm.stopLossLimit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1839511450)})],1):_vm._e()],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"4","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('b-card',{staticStyle:{"width":"100% !important"},attrs:{"title":_vm.$t('equity_bond.titles.security_details')}},[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.instrument_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.instrument_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-select',{class:_vm.tradeForm.instrumentType == 'EQ'
                                ? 'eq_color'
                                : 'fi_color',attrs:{"options":_vm.optInstrumentTypes,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_instrument_type'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.instrumentType),callback:function ($$v) {_vm.$set(_vm.tradeForm, "instrumentType", $$v)},expression:"tradeForm.instrumentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.security_desc')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.security_desc'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optSecurityDescriptions,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_security_desc'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.securityDesc),callback:function ($$v) {_vm.$set(_vm.tradeForm, "securityDesc", $$v)},expression:"tradeForm.securityDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.quantity'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","name":"quantity","type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.quantity'),"autofocus":"","disabled":true},model:{value:(_vm.tradeForm.quantity),callback:function ($$v) {_vm.$set(_vm.tradeForm, "quantity", _vm._n($$v))},expression:"tradeForm.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"8","xl":"8","lg":"8","md":"8","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client_price')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client_price'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"clientPrice","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.client_price'),"disabled":true},model:{value:(_vm.tradeForm.clientPrice),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientPrice", _vm._n($$v))},expression:"tradeForm.clientPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.price_type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.price_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optClientPriceTypes,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.price_type'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.clientPriceType),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientPriceType", $$v)},expression:"tradeForm.clientPriceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.amount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.amount'),"disabled":true},model:{value:(_vm.tradeForm.amount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "amount", _vm._n($$v))},expression:"tradeForm.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[(_vm.tradeForm.instrumentType == 'FI')?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.counterparty_price')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.counterparty_price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"counterpartyPrice","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.counterparty_price'),"disabled":true},model:{value:(_vm.tradeForm.counterpartyPrice),callback:function ($$v) {_vm.$set(_vm.tradeForm, "counterpartyPrice", _vm._n($$v))},expression:"tradeForm.counterpartyPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2347062428)})],1):_vm._e()],1),(_vm.tradeForm.instrumentType == 'FI')?_c('div',{staticClass:"d-flex",staticStyle:{"width":"100% !important"}},[_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"clean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":true},model:{value:(_vm.tradeForm.clean),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clean", $$v)},expression:"tradeForm.clean"}},[_vm._v(" "+_vm._s(_vm.$t('equity_bond.trade_form.clean'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3136312372)})],1)],1),_c('b-col',{attrs:{"cols":"9","xl":"9","lg":"9","md":"9","sm":"12"}},[(_vm.tradeForm.clean)?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.accrued')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.accrued')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"border":"1px solid #CD853F"},attrs:{"id":"accrued","name":"accrued","type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.accrued'),"autofocus":"","disabled":true},model:{value:(_vm.tradeForm.accrued),callback:function ($$v) {_vm.$set(_vm.tradeForm, "accrued", _vm._n($$v))},expression:"tradeForm.accrued"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3037850765)})],1):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optClients,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_client'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.client),callback:function ($$v) {_vm.$set(_vm.tradeForm, "client", $$v)},expression:"tradeForm.client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client_account')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client_account'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"id":"clientAccount","name":"clientAccount","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.client_account'),"autofocus":""},model:{value:(_vm.tradeForm.clientAccount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientAccount", $$v)},expression:"tradeForm.clientAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.isin')}},[_c('validation-provider',{attrs:{"name":"ISIN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"id":"isin","name":_vm.$t('equity_bond.trade_form.isin'),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.isin'),"autofocus":""},model:{value:(_vm.tradeForm.isin),callback:function ($$v) {_vm.$set(_vm.tradeForm, "isin", $$v)},expression:"tradeForm.isin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.exchange_traded')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.exchange_traded')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optExchangeTrades,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_exchange_traded'),"reduce":function (val) { return val; },"disabled":true},model:{value:(_vm.tradeForm.exchangeTraded),callback:function ($$v) {_vm.$set(_vm.tradeForm, "exchangeTraded", $$v)},expression:"tradeForm.exchangeTraded"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.counterparty')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.counterparty')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optCounterParties,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_counterparty'),"reduce":function (val) { return val; },"disabled":true},model:{value:(_vm.tradeForm.counterparty),callback:function ($$v) {_vm.$set(_vm.tradeForm, "counterparty", $$v)},expression:"tradeForm.counterparty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.cpty_account')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.cpty_account')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"id":"isin","name":"cpty account","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.cpty_account'),"autofocus":""},model:{value:(_vm.tradeForm.cptyAccount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "cptyAccount", $$v)},expression:"tradeForm.cptyAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.counterparty_trader')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.counterparty_trader')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optCounterPartyTraders,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_counterparty_trader'),"reduce":function (val) { return val; },"disabled":true},model:{value:(_vm.tradeForm.counterpartyTrader),callback:function ($$v) {_vm.$set(_vm.tradeForm, "counterpartyTrader", $$v)},expression:"tradeForm.counterpartyTrader"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.sfc_user')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.sfc_user'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optScfUsers,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_sfc_user'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.scfUser),callback:function ($$v) {_vm.$set(_vm.tradeForm, "scfUser", $$v)},expression:"tradeForm.scfUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.acting_capacity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.acting_capacity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optActingCapacities,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_acting_capacity'),"reduce":function (val) { return val; },"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.actingCapaticy),callback:function ($$v) {_vm.$set(_vm.tradeForm, "actingCapaticy", $$v)},expression:"tradeForm.actingCapaticy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"8","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"12","mf":"12","lg":"12","xl":"12"}},[_c('b-card',{staticStyle:{"width":"100% !important"},attrs:{"title":_vm.$t('equity_bond.titles.trade_date_time')}},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.trade_date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.trade_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.$t('equity_bond.trade_form.trade_date'),"onClose":"testClose","config":_vm.dateConfig,"disabled":true},model:{value:(_vm.tradeForm.tradeDate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "tradeDate", $$v)},expression:"tradeForm.tradeDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.settlement_date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.settlement_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":_vm.$t('equity_bond.trade_form.settlement_date'),"onClose":"testClose","config":_vm.dateConfig,"disabled":true},model:{value:(_vm.tradeForm.settlementDate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "settlementDate", $$v)},expression:"tradeForm.settlementDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.execution_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.execution_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"executionTime","raw":false,"options":_vm.cleaveOptions.time,"placeholder":_vm.$t('equity_bond.trade_form.execution_time'),"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.executionTime),callback:function ($$v) {_vm.$set(_vm.tradeForm, "executionTime", $$v)},expression:"tradeForm.executionTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[(_vm.tradeForm.instrumentType == 'FI')?_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.cpty_execution_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.cpty_execution_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cptyExecutionTime","raw":false,"options":_vm.cleaveOptions.time,"placeholder":_vm.$t('equity_bond.trade_form.cpty_execution_time'),"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.tradeForm.cptyExecutionTime),callback:function ($$v) {_vm.$set(_vm.tradeForm, "cptyExecutionTime", $$v)},expression:"tradeForm.cptyExecutionTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2621221216)})],1)],1):_vm._e()],1)])],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"12","mf":"12","lg":"12","xl":"12"}},[_c('b-card',{staticStyle:{"width":"100% !important"},attrs:{"title":_vm.$t('equity_bond.titles.trade_details')}},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"5","xl":"5","lg":"5","md":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client_rate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client_rate'),"rules":_vm.tradeForm.calculateCommissionFromRate1
                                ? 'min_value:0'
                                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Bps"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"plain":"","name":"checkbox-input","title":_vm.$t('equity_bond.trade_form.client_rate_tooltip'),"disabled":true},model:{value:(
                                    _vm.tradeForm.calculateCommissionFromRate1
                                  ),callback:function ($$v) {_vm.$set(_vm.tradeForm, "calculateCommissionFromRate1", $$v)},expression:"\n                                    tradeForm.calculateCommissionFromRate1\n                                  "}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":"clientRate","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.client_rate'),"disabled":true},model:{value:(_vm.tradeForm.clientRate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientRate", _vm._n($$v))},expression:"tradeForm.clientRate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client_comission')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client_comission'),"rules":!_vm.tradeForm.calculateCommissionFromRate1
                                ? 'min_value:0'
                                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"clientCommission","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.client_comission'),"disabled":true},model:{value:(_vm.tradeForm.clientCommission),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientCommission", _vm._n($$v))},expression:"tradeForm.clientCommission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.client_amount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.client_amount'),"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"clientAmmount","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.client_amount'),"disabled":true},model:{value:(_vm.tradeForm.clientAmount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "clientAmount", _vm._n($$v))},expression:"tradeForm.clientAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"5","xl":"5","lg":"5","md":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.counterparty_rate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.counterparty_rate'),"rules":_vm.tradeForm.calculateCommissionFromRate2
                                ? 'min_value:0'
                                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Bps"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],attrs:{"plain":"","name":"checkbox-input","title":_vm.$t('equity_bond.trade_form.counterparty_rate_tooltip'),"disabled":true},model:{value:(
                                    _vm.tradeForm.calculateCommissionFromRate2
                                  ),callback:function ($$v) {_vm.$set(_vm.tradeForm, "calculateCommissionFromRate2", $$v)},expression:"\n                                    tradeForm.calculateCommissionFromRate2\n                                  "}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":"counterpartyRate","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.counterparty_rate'),"disabled":true},model:{value:(_vm.tradeForm.counterpartyRate),callback:function ($$v) {_vm.$set(_vm.tradeForm, "counterpartyRate", _vm._n($$v))},expression:"tradeForm.counterpartyRate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.cpty_comission')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.cpty_comission'),"rules":!_vm.tradeForm.calculateCommissionFromRate2
                                ? 'min_value:0'
                                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cptyComission","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.cpty_comission'),"disabled":true},model:{value:(_vm.tradeForm.cptyCommission),callback:function ($$v) {_vm.$set(_vm.tradeForm, "cptyCommission", _vm._n($$v))},expression:"tradeForm.cptyCommission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.counterparty_amount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.counterparty_amount'),"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"counterpartyAmount","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.counterparty_amount'),"disabled":true},model:{value:(_vm.tradeForm.counterpartyAmount),callback:function ($$v) {_vm.$set(_vm.tradeForm, "counterpartyAmount", _vm._n($$v))},expression:"tradeForm.counterpartyAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.profit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.profit')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"profit","state":errors.length > 0 ? false : null,"raw":true,"options":_vm.cleaveOptions.number,"placeholder":_vm.$t('equity_bond.trade_form.profit'),"disabled":true},model:{value:(_vm.tradeForm.profit),callback:function ($$v) {_vm.$set(_vm.tradeForm, "profit", _vm._n($$v))},expression:"tradeForm.profit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_taken_via')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_taken_via')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optOrderTakenVias,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_order_taken_via'),"reduce":function (val) { return val; },"disabled":true},model:{value:(_vm.tradeForm.orderTakenVia),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderTakenVia", $$v)},expression:"tradeForm.orderTakenVia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.order_given_through')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.order_given_through')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.optOrderGivenThroughs,"clearable":false,"placeholder":_vm.$t('equity_bond.trade_form.select_order_given_through'),"reduce":function (val) { return val; },"disabled":true},model:{value:(_vm.tradeForm.orderGivenThrough),callback:function ($$v) {_vm.$set(_vm.tradeForm, "orderGivenThrough", $$v)},expression:"tradeForm.orderGivenThrough"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3","lg":"3","md":"3","sm":"12"}},[(_vm.tradeForm.instrumentType == 'EQ')?_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.tax')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.tax'),"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"border":"1px solid #191970"},attrs:{"id":"tax","name":"tax","type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.tax'),"autofocus":"","disabled":true},model:{value:(_vm.tradeForm.tax),callback:function ($$v) {_vm.$set(_vm.tradeForm, "tax", _vm._n($$v))},expression:"tradeForm.tax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,396394709)})],1):_vm._e()],1)],1),_c('div',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.note')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","placeholder":_vm.$t('equity_bond.trade_form.note'),"rows":"3","no-resize":"","disabled":true},model:{value:(_vm.tradeForm.note),callback:function ($$v) {_vm.$set(_vm.tradeForm, "note", $$v)},expression:"tradeForm.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"trade reporting needed"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"disabled":true},model:{value:(_vm.tradeForm.tradeReportingNeeded),callback:function ($$v) {_vm.$set(_vm.tradeForm, "tradeReportingNeeded", $$v)},expression:"tradeForm.tradeReportingNeeded"}},[_vm._v(" "+_vm._s(_vm.$t('equity_bond.trade_form.trade_reporting_needed'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"12","mf":"12","lg":"12","xl":"12"}},[_c('b-card',{staticStyle:{"width":"100% !important"},attrs:{"title":_vm.$t('equity_bond.titles.settlement_details')}},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.uti')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.uti')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"UTI","name":"UTI","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.uti'),"autofocus":"","disabled":true},model:{value:(_vm.tradeForm.uti),callback:function ($$v) {_vm.$set(_vm.tradeForm, "uti", $$v)},expression:"tradeForm.uti"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.unique_link_id')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.unique_link_id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"uniqueLinkID","type":"number","name":"uniqueLinkID","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.unique_link_id'),"autofocus":"","disabled":true},model:{value:(_vm.tradeForm.uniqueLinkID),callback:function ($$v) {_vm.$set(_vm.tradeForm, "uniqueLinkID", _vm._n($$v))},expression:"tradeForm.uniqueLinkID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex"}),_c('div',{staticClass:"d-flex"}),_c('div',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equity_bond.trade_form.trade_ref')}},[_c('validation-provider',{attrs:{"name":_vm.$t('equity_bond.trade_form.trade_ref')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"id":"tradeRef","name":"tradeRef","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('equity_bond.trade_form.trade_ref'),"autofocus":""},model:{value:(_vm.tradeForm.tradeRef),callback:function ($$v) {_vm.$set(_vm.tradeForm, "tradeRef", $$v)},expression:"tradeForm.tradeRef"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1),_vm._v(" "+_vm._s(/****END*** view for trades modal*/)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }