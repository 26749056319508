import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all verified client accounts
    getAllAccounts(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountCostCenter(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    // Arange TC users
    fetchArangeEquityBondTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArangeEquityBondTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Equity Bond Order Taken Given Through
    fetchArrangeEquityBondOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityBondOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Equity Bond Currencies
    fetchArrangeEquityBondCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityBondCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Search Equity&bond
    searchEquityBond(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchEquityBond`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    
    //Last Search Equity&bond
    getLastSearchEquityBond(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchEquityBond`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //filter Trade Date
    fetchFilterTradeDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterTradeDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchFilterSettlementDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterSettlementDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewAmendedSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewAmendedSelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getVerifySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAmendSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCopySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkAmendEntryType(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkAmendEntryType`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkUpdateAmendment(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkUpdateAmendment`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeAmendmentUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeAmendmentUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /// journal section

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCreateJournalTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateJournalTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewJournalTrade(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewJournalTrade`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCanceledEquityBondById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledEquityBondById`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAmendInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getReverseEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    reverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    undoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`undoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAmendDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    selectTexRateAsExchangeBuySell(ctx, exchange) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectTexRateAsExchangeBuySell`, exchange)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get holidays
    getHolidays(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //get Last 7 Days Equity Trades
    getLast7DaysEquityTrades(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysEquityTrades`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default Equity Entry.
    saveDefaultEntry(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Fx spot forward swap Order Taken Given Through
    fetchArrangeFxExecutedOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change Equity Status Opr.
    tradeMultipleChangeEquityStatusOpr(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeEquityStatusOpr`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select Equity Copy Opr.
    tradeMultipleSelectEquityCopyOpr(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleSelectEquityCopyOpr`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    exportXlsx(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportEquityXlsx',null ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportEquityCsv' ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
